import { useIntl } from "react-intl";
import { AnyArticleFilterInput } from "../../models/search-param";
import { main } from "./translations";
import { AccommodationType } from "@shared/models/article";
import { gql } from "@apollo/client";
import { useSearchParams } from "react-router-dom";
import { toGQLDate } from "@ct-react/core";

type SearchConfig = {
    request: AnyArticleFilterInput;
    pageTitle: string;
};

export const determineSearchConfig = (type: AccommodationType): SearchConfig => {
    const intl = useIntl();
    const [searchParams, setSearchParams] = useSearchParams();

    if (type === AccommodationType.RENTAL) {
      return {
        request: { rentalAccommodation: {
            roomsRange: !!searchParams.get("roomsRange") ?
                [parseInt(searchParams.get("roomsRange")![0]), parseInt(searchParams.get("roomsRange")![2])]
                :
                null,
            guests: parseInt(searchParams.get("minGuests")!) || null,
            period: !!searchParams.get("start") ?
                {period:[toGQLDate(searchParams.get("start")!), toGQLDate(searchParams.get("end")!)]}
            :
            null,
            objectTypes: !!searchParams.get("objectTypes") ?
            searchParams.get("objectTypes")!.split("-").map(e => parseInt(e))
            :
            null,
            wifi: !!searchParams.get("wifi") ?
            true
            :
            null,
            parking: !!searchParams.get("parking") ?
            true
            :
            null,
            pet: !!searchParams.get("pet") ?
            true
            :
            null,
            pool: !!searchParams.get("pool") ?
            true
            :
            null,
            cityCenter: !!searchParams.get("cityCenter") ?
            true
            :
            null,
            skiArea: !!searchParams.get("skiArea") ?
            true
            :
            null
        ,
        } },
        pageTitle: intl.formatMessage(main.search_articles_title_rental),
      };
    } else if (type === AccommodationType.SEASONAL) {
      return {
        request: { seasonalAccommodation: {
            roomsRange: !!searchParams.get("roomsRange") ?
                [parseInt(searchParams.get("roomsRange")![0]), parseInt(searchParams.get("roomsRange")![2])]
                :
                null,
            priceRange: !!searchParams.get("price") ?
                [parseInt(searchParams.get("price")!.split("-")[0]), parseInt(searchParams.get("price")!.split("-")[1])]
                :
                null,
            objectTypes: !!searchParams.get("objectTypes") ?
            searchParams.get("objectTypes")!.split("-").map(e => parseInt(e))
            :
            null,
        } },
        pageTitle: intl.formatMessage(main.search_articles_title_seasonal),
      };
    } else if (type === AccommodationType.ANNUAL) {
      return {
        request: { annualAccommodation: {
            roomsRange: !!searchParams.get("roomsRange") ?
                [parseInt(searchParams.get("roomsRange")![0]), parseInt(searchParams.get("roomsRange")![2])]
                :
                null,
            priceRange: !!searchParams.get("price") ?
                [parseInt(searchParams.get("price")!.split("-")[0]), parseInt(searchParams.get("price")!.split("-")[1])]
                :
                null,
            objectTypes: !!searchParams.get("objectTypes") ?
                searchParams.get("objectTypes")!.split("-").map(e => parseInt(e))
                :
                null,
            surface: parseInt(searchParams.get("minSurface")!) || null,
        } },
        pageTitle: intl.formatMessage(main.search_articles_title_annual),
      };
    } else {
      return {
        request: { saleAccommodation: {
            roomsRange: !!searchParams.get("roomsRange") ?
                [parseInt(searchParams.get("roomsRange")![0]), parseInt(searchParams.get("roomsRange")![2])]
                :
                null,
            priceRange: !!searchParams.get("price") ?
                [parseInt(searchParams.get("price")!.split("-")[0]), parseInt(searchParams.get("price")!.split("-")[1])]
                :
                null,
            objectTypes: !!searchParams.get("objectTypes") ?
                searchParams.get("objectTypes")!.split("-").map(e => parseInt(e))
                :
                null,
            surface: parseInt(searchParams.get("minSurface")!) || null,
        } },
        pageTitle: intl.formatMessage(main.search_articles_title_sale),
      };
    }
};

export const GET_ARTICLES = gql`
    query GET_ARTICLES(
        $type: AnyArticleFilterInput
        $first: Int,
        $last: Int,
        $before: String,
        $after: String,
    ){
    search(
            where: $type
            first: $first
            last: $last
            before: $before
            after: $after
    ) {
        totalCount
        edges {
            cursor
            node {
                ... on RentalAccommodationArticle {
                    id
                    slug
                    recommended
                    title {
                        matchLang
                        value
                    }
                    lowestPrice {
                        onDemand
                        type
                        value
                    }
                    discounts {
                        fixedValue
                        logic
                        percentageValue
                        period
                        type
                    }
                    features {
                        parkings
                        maxGuests
                        withWIFI
                    }
                    address {
                        city
                    }
                    images {
                        main
                        title {
                            matchLang
                            value
                        }
                        alternativeText {
                            matchLang
                            value
                        }
                        assets {
                            breakpoint
                            height
                            url
                            width
                        }
                    }
                },
                ... on SeasonalAccommodationArticle {
                    id
                    slug
                    recommended
                    title {
                        matchLang
                        value
                    }
                    price {
                        isMonthly
                        onDemand
                        period
                        value
                    }
                    address {
                        city
                    }
                    features {
                        parkings
                        maxGuests
                        withWIFI
                    }
                    images {
                        alternativeText {
                            matchLang
                            value
                        }
                        assets {
                            breakpoint
                            height
                            url
                            width
                        }
                    }
                },
                ... on AnnualAccommodationArticle {
                    id
                    slug
                    recommended
                    title {
                        matchLang
                        value
                    }
                    price {
                        availableAt
                        onDemand
                        value
                    }
                    address {
                        city
                    }
                    features {
                        parkings
                        livingSpace
                        rooms
                    }
                    images {
                        alternativeText {
                            matchLang
                            value
                        }
                        assets {
                            breakpoint
                            height
                            url
                            width
                        }
                    }
                },
                ... on SaleAccommodationArticle {
                    id
                    slug
                    recommended
                    novelty
                    promoted
                    title {
                        matchLang
                        value
                    }
                    price {
                        onDemand
                        value
                    }
                    address {
                        city
                    }
                    features {
                        parkings
                        livingSpace
                        rooms
                    }
                    images {
                        alternativeText {
                            matchLang
                            value
                        }
                        assets {
                            breakpoint
                            height
                            url
                            width
                        }
                    }
                }
            }
            accommodationBookingSuggestion {
                bookable
                period
                priceOnDemand
                price {
                    amount
                }
            }
        }
        accommodationMapMarkers {
            coordinates
            id
            slug
        }
        paginationInfo {
            currentPage
            hasNextPage
            hasPreviousPage
            lastPageCursor
            nextPageCursor
            pageCount
            pageSize
            previousPageCursor
            linkPageCursors {
                cursor
                page
            }
        }
    }
}
`;
